/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import type * as Types from '../../../../../shared/types/api.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PayseraEmailLoginMutationVariables = Types.Exact<{
	email: Types.Scalars['String']['input'];
	session_id: Types.Scalars['String']['input'];
}>;

export type PayseraEmailLoginMutation = {
	paysera_email_login?: boolean | null;
};

export type MagicLinkLoginMutationVariables = Types.Exact<{
	token: Types.Scalars['String']['input'];
}>;

export type MagicLinkLoginMutation = { magic_login?: boolean | null };

export const PayseraEmailLoginDocument = gql`
    mutation PayseraEmailLogin($email: String!, $session_id: String!) {
  paysera_email_login(email: $email, session_id: $session_id)
}
    `;
export type PayseraEmailLoginMutationFn = Apollo.MutationFunction<
	PayseraEmailLoginMutation,
	PayseraEmailLoginMutationVariables
>;

/**
 * __usePayseraEmailLoginMutation__
 *
 * To run a mutation, you first call `usePayseraEmailLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayseraEmailLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payseraEmailLoginMutation, { data, loading, error }] = usePayseraEmailLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      session_id: // value for 'session_id'
 *   },
 * });
 */
export function usePayseraEmailLoginMutation(
	baseOptions?: Apollo.MutationHookOptions<
		PayseraEmailLoginMutation,
		PayseraEmailLoginMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		PayseraEmailLoginMutation,
		PayseraEmailLoginMutationVariables
	>(PayseraEmailLoginDocument, options);
}
export type PayseraEmailLoginMutationHookResult = ReturnType<
	typeof usePayseraEmailLoginMutation
>;
export type PayseraEmailLoginMutationResult =
	Apollo.MutationResult<PayseraEmailLoginMutation>;
export type PayseraEmailLoginMutationOptions = Apollo.BaseMutationOptions<
	PayseraEmailLoginMutation,
	PayseraEmailLoginMutationVariables
>;
export const MagicLinkLoginDocument = gql`
    mutation MagicLinkLogin($token: String!) {
  magic_login(token: $token)
}
    `;
export type MagicLinkLoginMutationFn = Apollo.MutationFunction<
	MagicLinkLoginMutation,
	MagicLinkLoginMutationVariables
>;

/**
 * __useMagicLinkLoginMutation__
 *
 * To run a mutation, you first call `useMagicLinkLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMagicLinkLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [magicLinkLoginMutation, { data, loading, error }] = useMagicLinkLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useMagicLinkLoginMutation(
	baseOptions?: Apollo.MutationHookOptions<
		MagicLinkLoginMutation,
		MagicLinkLoginMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		MagicLinkLoginMutation,
		MagicLinkLoginMutationVariables
	>(MagicLinkLoginDocument, options);
}
export type MagicLinkLoginMutationHookResult = ReturnType<
	typeof useMagicLinkLoginMutation
>;
export type MagicLinkLoginMutationResult =
	Apollo.MutationResult<MagicLinkLoginMutation>;
export type MagicLinkLoginMutationOptions = Apollo.BaseMutationOptions<
	MagicLinkLoginMutation,
	MagicLinkLoginMutationVariables
>;
