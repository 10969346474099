/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import type * as Types from '../../../../shared/types/api.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EparakstsLoginChallengeMutationVariables = Types.Exact<{
	method: Types.EparakstsAuthorizationMethod;
	return_url: Types.Scalars['String']['input'];
}>;

export type EparakstsLoginChallengeMutation = {
	eparaksts_login_challenge?: { redirect_url: string } | null;
};

export const EparakstsLoginChallengeDocument = gql`
    mutation EparakstsLoginChallenge($method: EparakstsAuthorizationMethod!, $return_url: String!) {
  eparaksts_login_challenge(method: $method, return_url: $return_url) {
    redirect_url
  }
}
    `;
export type EparakstsLoginChallengeMutationFn = Apollo.MutationFunction<
	EparakstsLoginChallengeMutation,
	EparakstsLoginChallengeMutationVariables
>;

/**
 * __useEparakstsLoginChallengeMutation__
 *
 * To run a mutation, you first call `useEparakstsLoginChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEparakstsLoginChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eparakstsLoginChallengeMutation, { data, loading, error }] = useEparakstsLoginChallengeMutation({
 *   variables: {
 *      method: // value for 'method'
 *      return_url: // value for 'return_url'
 *   },
 * });
 */
export function useEparakstsLoginChallengeMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EparakstsLoginChallengeMutation,
		EparakstsLoginChallengeMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		EparakstsLoginChallengeMutation,
		EparakstsLoginChallengeMutationVariables
	>(EparakstsLoginChallengeDocument, options);
}
export type EparakstsLoginChallengeMutationHookResult = ReturnType<
	typeof useEparakstsLoginChallengeMutation
>;
export type EparakstsLoginChallengeMutationResult =
	Apollo.MutationResult<EparakstsLoginChallengeMutation>;
export type EparakstsLoginChallengeMutationOptions = Apollo.BaseMutationOptions<
	EparakstsLoginChallengeMutation,
	EparakstsLoginChallengeMutationVariables
>;
