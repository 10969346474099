/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import type * as Types from '../../../../shared/types/api.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserCreditAccountWithdrawalFormInfoQueryVariables = Types.Exact<{
	[key: string]: never;
}>;

export type UserCreditAccountWithdrawalFormInfoQuery = {
	me?: {
		id: number;
		disposable_income_without_ca?: number | null;
		credit_accounts?: Array<{
			id: number;
			credit_limit: number;
			status?: Types.CreditAccountStatus | null;
			unpaid_principal: number;
			annual_pct_rate: number;
			max_period_months: number;
			instant_payment_fee: number;
		} | null> | null;
	} | null;
	pricing?: Array<{ key: string; value: string } | null> | null;
};

export const UserCreditAccountWithdrawalFormInfoDocument = gql`
    query UserCreditAccountWithdrawalFormInfo {
  me(is_me: true) {
    id
    disposable_income_without_ca
    credit_accounts {
      id
      credit_limit
      status
      unpaid_principal
      annual_pct_rate
      max_period_months
      instant_payment_fee
    }
  }
  pricing(
    keys: ["credit_acc.min_withdrawal_amount", "credit_acc.min_instant_withdrawal_amount"]
  ) {
    key
    value
  }
}
    `;

/**
 * __useUserCreditAccountWithdrawalFormInfoQuery__
 *
 * To run a query within a React component, call `useUserCreditAccountWithdrawalFormInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreditAccountWithdrawalFormInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreditAccountWithdrawalFormInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCreditAccountWithdrawalFormInfoQuery(
	baseOptions?: Apollo.QueryHookOptions<
		UserCreditAccountWithdrawalFormInfoQuery,
		UserCreditAccountWithdrawalFormInfoQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		UserCreditAccountWithdrawalFormInfoQuery,
		UserCreditAccountWithdrawalFormInfoQueryVariables
	>(UserCreditAccountWithdrawalFormInfoDocument, options);
}
export function useUserCreditAccountWithdrawalFormInfoLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		UserCreditAccountWithdrawalFormInfoQuery,
		UserCreditAccountWithdrawalFormInfoQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		UserCreditAccountWithdrawalFormInfoQuery,
		UserCreditAccountWithdrawalFormInfoQueryVariables
	>(UserCreditAccountWithdrawalFormInfoDocument, options);
}
export function useUserCreditAccountWithdrawalFormInfoSuspenseQuery(
	baseOptions?: Apollo.SuspenseQueryHookOptions<
		UserCreditAccountWithdrawalFormInfoQuery,
		UserCreditAccountWithdrawalFormInfoQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<
		UserCreditAccountWithdrawalFormInfoQuery,
		UserCreditAccountWithdrawalFormInfoQueryVariables
	>(UserCreditAccountWithdrawalFormInfoDocument, options);
}
export type UserCreditAccountWithdrawalFormInfoQueryHookResult = ReturnType<
	typeof useUserCreditAccountWithdrawalFormInfoQuery
>;
export type UserCreditAccountWithdrawalFormInfoLazyQueryHookResult = ReturnType<
	typeof useUserCreditAccountWithdrawalFormInfoLazyQuery
>;
export type UserCreditAccountWithdrawalFormInfoSuspenseQueryHookResult =
	ReturnType<typeof useUserCreditAccountWithdrawalFormInfoSuspenseQuery>;
export type UserCreditAccountWithdrawalFormInfoQueryResult = Apollo.QueryResult<
	UserCreditAccountWithdrawalFormInfoQuery,
	UserCreditAccountWithdrawalFormInfoQueryVariables
>;
