import { useCallback } from 'react';

type UseCalculateNewMonthlyPaymentParams = {
	annualPctRate: number;
	maxPeriodMonths: number;
	unpaidPrincipal: number;
};

export const useCalculateNewMonthlyPayment = ({
	annualPctRate,
	maxPeriodMonths,
	unpaidPrincipal,
}: UseCalculateNewMonthlyPaymentParams) => {
	return useCallback(
		(newAmount: number) => {
			const principal = unpaidPrincipal + newAmount;
			const interestPerMonth = (principal * (annualPctRate / 100)) / 12;

			return Math.max(
				interestPerMonth + principal / maxPeriodMonths,
				Math.min(interestPerMonth + principal, 10),
			);
		},
		[annualPctRate, maxPeriodMonths, unpaidPrincipal],
	);
};
