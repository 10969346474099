/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import type * as Types from '../../../../shared/types/api.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PayseraLoginQueryVariables = Types.Exact<{
	session_id: Types.Scalars['String']['input'];
}>;

export type PayseraLoginQuery = { status?: Types.LoginPollStatus | null };

export const PayseraLoginDocument = gql`
    query PayseraLogin($session_id: String!) {
  status: paysera_login(session_id: $session_id)
}
    `;

/**
 * __usePayseraLoginQuery__
 *
 * To run a query within a React component, call `usePayseraLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayseraLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayseraLoginQuery({
 *   variables: {
 *      session_id: // value for 'session_id'
 *   },
 * });
 */
export function usePayseraLoginQuery(
	baseOptions: Apollo.QueryHookOptions<
		PayseraLoginQuery,
		PayseraLoginQueryVariables
	> &
		(
			| { variables: PayseraLoginQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PayseraLoginQuery, PayseraLoginQueryVariables>(
		PayseraLoginDocument,
		options,
	);
}
export function usePayseraLoginLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		PayseraLoginQuery,
		PayseraLoginQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PayseraLoginQuery, PayseraLoginQueryVariables>(
		PayseraLoginDocument,
		options,
	);
}
export function usePayseraLoginSuspenseQuery(
	baseOptions?: Apollo.SuspenseQueryHookOptions<
		PayseraLoginQuery,
		PayseraLoginQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<PayseraLoginQuery, PayseraLoginQueryVariables>(
		PayseraLoginDocument,
		options,
	);
}
export type PayseraLoginQueryHookResult = ReturnType<
	typeof usePayseraLoginQuery
>;
export type PayseraLoginLazyQueryHookResult = ReturnType<
	typeof usePayseraLoginLazyQuery
>;
export type PayseraLoginSuspenseQueryHookResult = ReturnType<
	typeof usePayseraLoginSuspenseQuery
>;
export type PayseraLoginQueryResult = Apollo.QueryResult<
	PayseraLoginQuery,
	PayseraLoginQueryVariables
>;
