import type { AppLanguage } from '@/shared/types';
import type { ActiveLanguagesQuery } from '../api';
import { LANGUAGE_SHORTNAME_BY_ABBREVIATIONS_MAP } from '../constants';

export const serializeActiveLanguages = (
	data: ActiveLanguagesQuery,
): Array<AppLanguage> =>
	data?.active_languages?.reduce<Array<AppLanguage>>((acc, lang) => {
		if (lang) {
			acc.push(LANGUAGE_SHORTNAME_BY_ABBREVIATIONS_MAP[lang.abbreviation]);
		}
		return acc;
	}, []) ?? [];
