import { LoginPollStatus } from '@/shared/types';
import { APP_CONFIG } from '@config/app';
import { getUserFx } from '@entities/user';
import { EPARAKSTS_LOGIN_CODE_SEARCH_NAME } from '@features/auth/by-eparaksts';
import {
	PAYSERA_LOGIN_STATUS_SEARCH_NAME,
	PayseraLoginStatus,
} from '@features/auth/by-paysera-banklink/login';
import {
	MagicLinkLoginDocument,
	type MagicLinkLoginMutation,
	type MagicLinkLoginMutationVariables,
} from '@features/auth/by-paysera-banklink/magic-link/api';
import { LOCAL_STORAGE_SESSION_ID_KEY } from '@hooks/auth/useLocalStorageSessionId';
import { createFileRoute, redirect } from '@tanstack/react-router';
import {
	PayseraLoginDocument,
	type PayseraLoginQuery,
	type PayseraLoginQueryVariables,
} from '@widgets/auth/paysera-banklink-form/api';
import { toast } from 'sonner';
import * as z from 'zod';

const authSearchSchema = z.object({
	authMethod: z.enum(APP_CONFIG.authMethods).catch(APP_CONFIG.authMethods[0]),
	invite: z.string().optional().catch(undefined),
	token: z.string().optional().catch(undefined),
	[PAYSERA_LOGIN_STATUS_SEARCH_NAME]: z
		.nativeEnum(PayseraLoginStatus)
		.optional()
		.catch(undefined),
	redirectUrl: z.string().optional().catch(undefined),
	redirect_back: z.string().optional().catch(undefined),
	// eparaksts auth code
	[EPARAKSTS_LOGIN_CODE_SEARCH_NAME]: z.string().optional().catch(undefined),
});

export const Route = createFileRoute('/_unprotected/auth')({
	loaderDeps: ({ search: { token, payseraLoginStatus, redirectUrl } }) => ({
		token,
		payseraLoginStatus,
		redirectUrl,
	}),
	loader: async ({
		deps: { token, payseraLoginStatus, redirectUrl },
		context: { apolloClient },
	}) => {
		const sessionId = localStorage.getItem(LOCAL_STORAGE_SESSION_ID_KEY);

		if (payseraLoginStatus === PayseraLoginStatus.SUCCESS && !!sessionId) {
			const { data } = await apolloClient.query<
				PayseraLoginQuery,
				PayseraLoginQueryVariables
			>({
				query: PayseraLoginDocument,
				variables: {
					session_id: sessionId,
				},
				fetchPolicy: 'network-only',
			});

			if (data?.status === LoginPollStatus.SUCCESSFUL) {
				const user = await getUserFx();

				if (!user) {
					toast('Something went wrong. Please try again');
					return;
				}

				throw redirect({
					to: redirectUrl ?? '/auth-additional-info',
					replace: true,
				});
			}
		}

		// Magic link login
		if (token) {
			const { data } = await apolloClient.mutate<
				MagicLinkLoginMutation,
				MagicLinkLoginMutationVariables
			>({
				mutation: MagicLinkLoginDocument,
				variables: {
					token,
				},
				fetchPolicy: 'network-only',
			});

			if (data?.magic_login) {
				const user = await getUserFx();

				if (!user) {
					toast('Something went wrong. Please try again');
					return;
				}

				throw redirect({
					to: redirectUrl ?? '/auth-additional-info',
					replace: true,
				});
			}
		}
	},
	validateSearch: authSearchSchema,
});
