import { GOOGLE_TAG_MANAGER_ID } from '@config/envs';
import { GoogleTagManager } from '@lib/googleTagManager';
import { createEffect, createEvent, createStore, sample } from 'effector';

const initEv = createEvent('init google tag manager');

const initFx = createEffect((gtmId: string) => {
	GoogleTagManager.initialize(gtmId);
});

const $isInitialized = createStore(false).on(initFx.doneData, () => true);

sample({
	clock: initEv,
	source: $isInitialized,
	filter: (isInitialized) => !isInitialized && !!GOOGLE_TAG_MANAGER_ID,
	fn: () => GOOGLE_TAG_MANAGER_ID ?? '',
	target: initFx,
});

export const googleAnalyticsModel = {
	initEv,
};
