import { routeTree } from '@/routeTree.gen';
import { apolloClient } from '@/shared/lib/apolloClient';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { Suspense } from 'react';

const router = createRouter({
	routeTree,
	defaultPreload: false,
	context: {
		apolloClient,
	},
	notFoundMode: 'root',
});

declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
}
export const AppRouter = () => (
	<Suspense>
		<RouterProvider router={router} />
	</Suspense>
);
