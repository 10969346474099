import CancelIcon from '@/shared/assets/icons/close.svg?react';
import { Typography } from '@components/typography';
import { Button } from '@components/ui/button';
import { APP_CONFIG } from '@config/app';
import { LOCIZE_ERROR_SCREEN_KEYS, LOCIZE_NAMESPACES } from '@config/locize';
import { $isAuthorized } from '@entities/user';
import { Link } from '@tanstack/react-router';
import { useUnit } from 'effector-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export const NotFoundPage = () => {
	const isAuthorized = useUnit($isAuthorized);
	const { t, i18n } = useTranslation(LOCIZE_NAMESPACES.errorScreen);

	return (
		<>
			<Helmet title="404" />
			<div className="flex size-full flex-col justify-center md:justify-start px-8 pb-20 pt-28 text-center">
				<div className="w-full flex flex-col items-center mx-auto max-w-[25rem]">
					<CancelIcon className="mb-9" />
					<Typography variant="m" className="mb-8">
						{t(LOCIZE_ERROR_SCREEN_KEYS.title)}
					</Typography>
					<Typography variant="text-l" className="mb-14">
						{t(LOCIZE_ERROR_SCREEN_KEYS.notFoundDescription)}
					</Typography>
					<div className="grid gap-4 w-full">
						<Button fullWidth asChild>
							{isAuthorized ? (
								<Link to="/dashboard" replace>
									{t(LOCIZE_ERROR_SCREEN_KEYS.goHomeButton)}
								</Link>
							) : (
								<Link
									to="/auth"
									replace
									search={{
										authMethod: APP_CONFIG.authMethods[0],
									}}
								>
									{t(LOCIZE_ERROR_SCREEN_KEYS.goHomeButton)}
								</Link>
							)}
						</Button>
						<Button fullWidth asChild variant="grey">
							<a href={APP_CONFIG.supportUrlByLanguage[i18n.language]}>
								{t(LOCIZE_ERROR_SCREEN_KEYS.supportButtonText)}
							</a>
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};
