export * from './auth';
export * from './auth-additional-info';
export * from './sidebar';
export * from './common';
export * from './dashboard';
export * from './agreements';
export * from './withdrawal';
export * from './errors';
export * from './offers';
export * from './error-screen';
export * from './loading-screen';
export * from './premium-page';
export * from './subscribe-newsletter-page';

export const LOCIZE_NAMESPACES = {
	auth: 'auth',
	authAdditionalInfo: 'auth-additional-info',
	sidebar: 'sidebar',
	common: 'common',
	dashboard: 'dashboard',
	agreements: 'agreements',
	withdrawal: 'withdrawal',
	errors: 'errors',
	offers: 'offers',
	errorScreen: 'error-screen',
	loadingScreen: 'loading-screen',
	premiumPage: 'premium-page',
	subscribeNewsletterPage: 'subscribe-newsletter-page',
} as const;
