import { getFullName } from '@/shared/utils/getFullName';
import { GRAPHQL_SERVER_URL, SMARTLOOK_PROJECT_ID } from '@config/envs';
import { $user } from '@entities/user';
import {
	attach,
	createEffect,
	createEvent,
	createStore,
	sample,
} from 'effector';
import Smartlook from 'smartlook-client';

const initEv = createEvent('init smartlook');

const initFx = createEffect((smartlookId: string) => {
	Smartlook.init(smartlookId, {
		advancedNetwork: {
			allowedUrls: [GRAPHQL_SERVER_URL],
		},
	});
	Smartlook.record({
		forms: true,
		emails: true,
		numbers: true,
		api: true,
		ips: true,
	});
});

const identifyUserFx = attach({
	source: $user,
	effect(user) {
		if (!user) {
			return;
		}

		const { id, email, profile } = user;

		Smartlook.identify(id, {
			name: getFullName({
				firstName: profile?.first_name,
				lastName: profile?.last_name,
				defaultValue: 'No Name',
			}),
			email: email || 'No Email',
		});
	},
});

const $isInitialized = createStore(false).on(initFx.done, () => true);

sample({
	clock: initEv,
	source: $isInitialized,
	filter: (isInitialized) => !isInitialized && !!SMARTLOOK_PROJECT_ID,
	fn: () => SMARTLOOK_PROJECT_ID ?? '',
	target: initFx,
});

sample({
	source: { user: $user, isInitialized: $isInitialized },
	filter: ({ user, isInitialized }) => !!user && isInitialized,
	fn: ({ user }) => user,
	target: identifyUserFx,
});

export const smartlookModel = {
	initEv,
};
