/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import type * as Types from '../../../shared/types/api.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveLanguageFragment = { abbreviation: string };

export type ActiveLanguagesQueryVariables = Types.Exact<{
	[key: string]: never;
}>;

export type ActiveLanguagesQuery = {
	active_languages?: Array<{ abbreviation: string } | null> | null;
};

export const ActiveLanguageFragmentDoc = gql`
    fragment ActiveLanguage on Language {
  abbreviation
}
    `;
export const ActiveLanguagesDocument = gql`
    query ActiveLanguages {
  active_languages {
    ...ActiveLanguage
  }
}
    ${ActiveLanguageFragmentDoc}`;

/**
 * __useActiveLanguagesQuery__
 *
 * To run a query within a React component, call `useActiveLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveLanguagesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ActiveLanguagesQuery,
		ActiveLanguagesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ActiveLanguagesQuery, ActiveLanguagesQueryVariables>(
		ActiveLanguagesDocument,
		options,
	);
}
export function useActiveLanguagesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ActiveLanguagesQuery,
		ActiveLanguagesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ActiveLanguagesQuery,
		ActiveLanguagesQueryVariables
	>(ActiveLanguagesDocument, options);
}
export function useActiveLanguagesSuspenseQuery(
	baseOptions?: Apollo.SuspenseQueryHookOptions<
		ActiveLanguagesQuery,
		ActiveLanguagesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<
		ActiveLanguagesQuery,
		ActiveLanguagesQueryVariables
	>(ActiveLanguagesDocument, options);
}
export type ActiveLanguagesQueryHookResult = ReturnType<
	typeof useActiveLanguagesQuery
>;
export type ActiveLanguagesLazyQueryHookResult = ReturnType<
	typeof useActiveLanguagesLazyQuery
>;
export type ActiveLanguagesSuspenseQueryHookResult = ReturnType<
	typeof useActiveLanguagesSuspenseQuery
>;
export type ActiveLanguagesQueryResult = Apollo.QueryResult<
	ActiveLanguagesQuery,
	ActiveLanguagesQueryVariables
>;
