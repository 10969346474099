/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import type * as Types from '../../../shared/types/api.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserAdditionalInfoFragment = {
	id: number;
	email?: string | null;
	phone?: string | null;
	language_abbr: string;
	newsletter_agreement: boolean;
	created_at: number;
	profile?: { user_id: number; first_name?: string | null } | null;
};

export type UserAdditionalInfoQueryVariables = Types.Exact<{
	[key: string]: never;
}>;

export type UserAdditionalInfoQuery = {
	me?: {
		id: number;
		email?: string | null;
		phone?: string | null;
		language_abbr: string;
		newsletter_agreement: boolean;
		created_at: number;
		profile?: { user_id: number; first_name?: string | null } | null;
	} | null;
};

export const UserAdditionalInfoFragmentDoc = gql`
    fragment UserAdditionalInfo on User {
  id
  email
  phone
  language_abbr
  newsletter_agreement
  created_at
  profile {
    user_id
    first_name
  }
}
    `;
export const UserAdditionalInfoDocument = gql`
    query UserAdditionalInfo {
  me(is_me: true) {
    ...UserAdditionalInfo
  }
}
    ${UserAdditionalInfoFragmentDoc}`;

/**
 * __useUserAdditionalInfoQuery__
 *
 * To run a query within a React component, call `useUserAdditionalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAdditionalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAdditionalInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAdditionalInfoQuery(
	baseOptions?: Apollo.QueryHookOptions<
		UserAdditionalInfoQuery,
		UserAdditionalInfoQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		UserAdditionalInfoQuery,
		UserAdditionalInfoQueryVariables
	>(UserAdditionalInfoDocument, options);
}
export function useUserAdditionalInfoLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		UserAdditionalInfoQuery,
		UserAdditionalInfoQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		UserAdditionalInfoQuery,
		UserAdditionalInfoQueryVariables
	>(UserAdditionalInfoDocument, options);
}
export function useUserAdditionalInfoSuspenseQuery(
	baseOptions?: Apollo.SuspenseQueryHookOptions<
		UserAdditionalInfoQuery,
		UserAdditionalInfoQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<
		UserAdditionalInfoQuery,
		UserAdditionalInfoQueryVariables
	>(UserAdditionalInfoDocument, options);
}
export type UserAdditionalInfoQueryHookResult = ReturnType<
	typeof useUserAdditionalInfoQuery
>;
export type UserAdditionalInfoLazyQueryHookResult = ReturnType<
	typeof useUserAdditionalInfoLazyQuery
>;
export type UserAdditionalInfoSuspenseQueryHookResult = ReturnType<
	typeof useUserAdditionalInfoSuspenseQuery
>;
export type UserAdditionalInfoQueryResult = Apollo.QueryResult<
	UserAdditionalInfoQuery,
	UserAdditionalInfoQueryVariables
>;
