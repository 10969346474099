import { RouteName } from '@/shared/config/routes';
import { AppAuthMethod } from '@/shared/types';
import { generateLinkWithSearchParams } from '@/shared/utils/generateLinkWithSearchParams';
import type { MutationHookOptions } from '@apollo/client';
import {
	type PayseraLoginChallengeMutation,
	type PayseraLoginChallengeMutationVariables,
	usePayseraLoginChallengeMutation,
} from '@features/auth/by-paysera-banklink/login/api';
import {
	PAYSERA_LOGIN_STATUS_SEARCH_NAME,
	PayseraLoginStatus,
} from '@features/auth/by-paysera-banklink/login/constants';
import { useLocalStorageSessionId } from '@hooks/auth';
import { useSearch } from '@tanstack/react-router';

const AUTH_FULL_LINK = `${window.origin}/${RouteName.AUTH}`;

type UsePayseraBanklinkLoginParams = MutationHookOptions<
	PayseraLoginChallengeMutation,
	PayseraLoginChallengeMutationVariables
>;

export const usePayseraBanklinkLogin = (
	baseOptions?: UsePayseraBanklinkLoginParams,
) => {
	const { invite } = useSearch({
		from: '/_unprotected/auth',
	});
	const { setLocalStorageSessionId } = useLocalStorageSessionId();

	const [payseraLoginMutation] = usePayseraLoginChallengeMutation(baseOptions);

	const handlePayseraLogin = async ({
		pin,
		payment_method_key,
	}: Pick<
		PayseraLoginChallengeMutationVariables,
		'pin' | 'payment_method_key'
	>) => {
		const { data } = await payseraLoginMutation({
			variables: {
				pin,
				invite,
				payment_method_key,
				accept_url: generateLinkWithSearchParams({
					link: AUTH_FULL_LINK,
					searchParams: {
						[PAYSERA_LOGIN_STATUS_SEARCH_NAME]: PayseraLoginStatus.SUCCESS,
						authMethod: AppAuthMethod.PAYSERA_BANKLINK,
					},
				}),
				cancel_url: generateLinkWithSearchParams({
					link: AUTH_FULL_LINK,
					searchParams: {
						[PAYSERA_LOGIN_STATUS_SEARCH_NAME]: PayseraLoginStatus.ERROR,
						authMethod: AppAuthMethod.PAYSERA_BANKLINK,
					},
				}),
				magic_login_url: AUTH_FULL_LINK,
			},
		});

		const { session_id, redirect_url } = data?.paysera_login_challenge ?? {};

		if (!session_id || !redirect_url) {
			throw new Error('No session_id or redirect_url');
		}

		setLocalStorageSessionId(session_id);
		window.open(redirect_url);
	};

	return {
		handlePayseraLogin,
	};
};
