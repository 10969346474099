/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import type * as Types from '../../../shared/types/api.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserAuthInfoFragment = {
	id: number;
	email?: string | null;
	phone?: string | null;
	newsletter_agreement: boolean;
	disposable_income_without_ca?: number | null;
	language_abbr: string;
	profile?: {
		user_id: number;
		first_name?: string | null;
		last_name?: string | null;
	} | null;
};

export type AuthCheckQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AuthCheckQuery = {
	me?: {
		id: number;
		email?: string | null;
		phone?: string | null;
		newsletter_agreement: boolean;
		disposable_income_without_ca?: number | null;
		language_abbr: string;
		profile?: {
			user_id: number;
			first_name?: string | null;
			last_name?: string | null;
		} | null;
	} | null;
};

export type UserCreditAccountQueryVariables = Types.Exact<{
	[key: string]: never;
}>;

export type UserCreditAccountQuery = {
	me?: {
		id: number;
		credit_accounts?: Array<{
			id: number;
			credit_limit: number;
			status?: Types.CreditAccountStatus | null;
			unpaid_principal: number;
			can_request_limit_increase: boolean;
			signed_at?: string | null;
			withdrawals?: Array<{ id: number } | null> | null;
		} | null> | null;
	} | null;
};

export type UserPremiumSubscriptionQueryVariables = Types.Exact<{
	[key: string]: never;
}>;

export type UserPremiumSubscriptionQuery = {
	me?: {
		id: number;
		premium_subscription_status: string;
		active_premium_subscription?: {
			id: number;
			fee_amount: number;
			end_at: string;
		} | null;
	} | null;
};

export const UserAuthInfoFragmentDoc = gql`
    fragment UserAuthInfo on User {
  id
  email
  phone
  newsletter_agreement
  disposable_income_without_ca
  profile {
    user_id
    first_name
    last_name
  }
  language_abbr
}
    `;
export const AuthCheckDocument = gql`
    query AuthCheck {
  me(is_me: true) {
    ...UserAuthInfo
  }
}
    ${UserAuthInfoFragmentDoc}`;

/**
 * __useAuthCheckQuery__
 *
 * To run a query within a React component, call `useAuthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthCheckQuery(
	baseOptions?: Apollo.QueryHookOptions<
		AuthCheckQuery,
		AuthCheckQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AuthCheckQuery, AuthCheckQueryVariables>(
		AuthCheckDocument,
		options,
	);
}
export function useAuthCheckLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		AuthCheckQuery,
		AuthCheckQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AuthCheckQuery, AuthCheckQueryVariables>(
		AuthCheckDocument,
		options,
	);
}
export function useAuthCheckSuspenseQuery(
	baseOptions?: Apollo.SuspenseQueryHookOptions<
		AuthCheckQuery,
		AuthCheckQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<AuthCheckQuery, AuthCheckQueryVariables>(
		AuthCheckDocument,
		options,
	);
}
export type AuthCheckQueryHookResult = ReturnType<typeof useAuthCheckQuery>;
export type AuthCheckLazyQueryHookResult = ReturnType<
	typeof useAuthCheckLazyQuery
>;
export type AuthCheckSuspenseQueryHookResult = ReturnType<
	typeof useAuthCheckSuspenseQuery
>;
export type AuthCheckQueryResult = Apollo.QueryResult<
	AuthCheckQuery,
	AuthCheckQueryVariables
>;
export const UserCreditAccountDocument = gql`
    query UserCreditAccount {
  me(is_me: true) {
    id
    credit_accounts {
      id
      credit_limit
      status
      unpaid_principal
      can_request_limit_increase
      signed_at
      withdrawals {
        id
      }
    }
  }
}
    `;

/**
 * __useUserCreditAccountQuery__
 *
 * To run a query within a React component, call `useUserCreditAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreditAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreditAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCreditAccountQuery(
	baseOptions?: Apollo.QueryHookOptions<
		UserCreditAccountQuery,
		UserCreditAccountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		UserCreditAccountQuery,
		UserCreditAccountQueryVariables
	>(UserCreditAccountDocument, options);
}
export function useUserCreditAccountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		UserCreditAccountQuery,
		UserCreditAccountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		UserCreditAccountQuery,
		UserCreditAccountQueryVariables
	>(UserCreditAccountDocument, options);
}
export function useUserCreditAccountSuspenseQuery(
	baseOptions?: Apollo.SuspenseQueryHookOptions<
		UserCreditAccountQuery,
		UserCreditAccountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<
		UserCreditAccountQuery,
		UserCreditAccountQueryVariables
	>(UserCreditAccountDocument, options);
}
export type UserCreditAccountQueryHookResult = ReturnType<
	typeof useUserCreditAccountQuery
>;
export type UserCreditAccountLazyQueryHookResult = ReturnType<
	typeof useUserCreditAccountLazyQuery
>;
export type UserCreditAccountSuspenseQueryHookResult = ReturnType<
	typeof useUserCreditAccountSuspenseQuery
>;
export type UserCreditAccountQueryResult = Apollo.QueryResult<
	UserCreditAccountQuery,
	UserCreditAccountQueryVariables
>;
export const UserPremiumSubscriptionDocument = gql`
    query UserPremiumSubscription {
  me(is_me: true) {
    id
    premium_subscription_status
    active_premium_subscription {
      id
      fee_amount
      end_at
    }
  }
}
    `;

/**
 * __useUserPremiumSubscriptionQuery__
 *
 * To run a query within a React component, call `useUserPremiumSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPremiumSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPremiumSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPremiumSubscriptionQuery(
	baseOptions?: Apollo.QueryHookOptions<
		UserPremiumSubscriptionQuery,
		UserPremiumSubscriptionQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		UserPremiumSubscriptionQuery,
		UserPremiumSubscriptionQueryVariables
	>(UserPremiumSubscriptionDocument, options);
}
export function useUserPremiumSubscriptionLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		UserPremiumSubscriptionQuery,
		UserPremiumSubscriptionQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		UserPremiumSubscriptionQuery,
		UserPremiumSubscriptionQueryVariables
	>(UserPremiumSubscriptionDocument, options);
}
export function useUserPremiumSubscriptionSuspenseQuery(
	baseOptions?: Apollo.SuspenseQueryHookOptions<
		UserPremiumSubscriptionQuery,
		UserPremiumSubscriptionQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<
		UserPremiumSubscriptionQuery,
		UserPremiumSubscriptionQueryVariables
	>(UserPremiumSubscriptionDocument, options);
}
export type UserPremiumSubscriptionQueryHookResult = ReturnType<
	typeof useUserPremiumSubscriptionQuery
>;
export type UserPremiumSubscriptionLazyQueryHookResult = ReturnType<
	typeof useUserPremiumSubscriptionLazyQuery
>;
export type UserPremiumSubscriptionSuspenseQueryHookResult = ReturnType<
	typeof useUserPremiumSubscriptionSuspenseQuery
>;
export type UserPremiumSubscriptionQueryResult = Apollo.QueryResult<
	UserPremiumSubscriptionQuery,
	UserPremiumSubscriptionQueryVariables
>;
