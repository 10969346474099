import * as z from 'zod';
import {
	AppLanguage,
	OldApplicationRedirectStatus,
	SupportedCountries,
} from '../types';

const envVariables = z.object({
	REACT_APP_COUNTRY: z.nativeEnum(SupportedCountries),
	NODE_ENV: z.string(),
	REACT_APP_API_ENDPOINT: z.string(),
	REACT_APP_ID_CARD_AUTHENTICATION_URL: z.string(),
	REACT_APP_LOCIZE_PROJECT_ID: z.string(),
	REACT_APP_REGION_DEFAULT_LANGUAGE: z.nativeEnum(AppLanguage),
	REACT_APP_OLD_APP_DOMAIN: z.string(),
	REACT_APP_PURCHASE_FLOW_DOMAIN: z.string(),
	REACT_APP_GOOGLE_TAG_MANAGER_ID: z.string().optional(),
	REACT_APP_SMARTLOOK_PROJECT_ID: z.string().optional(),
	REACT_APP_REDIRECT_OLD_CP_STATUS: z
		.nativeEnum(OldApplicationRedirectStatus)
		.catch(OldApplicationRedirectStatus.INACTIVE),
	REACT_APP_SIFT_BEACON_KEY: z.string().optional(),
});

envVariables.parse(process.env);

declare global {
	namespace NodeJS {
		interface ProcessEnv extends z.infer<typeof envVariables> {}
	}
}

export const GRAPHQL_SERVER_URL = process.env.REACT_APP_API_ENDPOINT;
export const ID_CARD_AUTHENTICATION_URL =
	process.env.REACT_APP_ID_CARD_AUTHENTICATION_URL;
export const LOCIZE_PROJECT_ID = process.env.REACT_APP_LOCIZE_PROJECT_ID;
export const REGION_DEFAULT_LANGUAGE =
	process.env.REACT_APP_REGION_DEFAULT_LANGUAGE;
export const ENVIRONMENT = process.env.NODE_ENV;
export const APP_COUNTRY = process.env.REACT_APP_COUNTRY;
export const OLD_APP_DOMAIN = process.env.REACT_APP_OLD_APP_DOMAIN;
export const PURCHASE_FLOW_DOMAIN = process.env.REACT_APP_PURCHASE_FLOW_DOMAIN;
export const GOOGLE_TAG_MANAGER_ID =
	process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
export const SMARTLOOK_PROJECT_ID = process.env.REACT_APP_SMARTLOOK_PROJECT_ID;
export const RUDDERSTACK_API_KEY = process.env.REACT_APP_RUDDERSTACK_API_KEY;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SIFT_BEACON_KEY = process.env.REACT_APP_SIFT_BEACON_KEY;

export const isProd = ENVIRONMENT === 'production';
export const shouldRedirectToOldApplication =
	process.env.REACT_APP_REDIRECT_OLD_CP_STATUS ===
	OldApplicationRedirectStatus.ACTIVE;
