import { OLD_APP_DOMAIN, shouldRedirectToOldApplication } from '@config/envs';
import { $isAuthorized } from '@entities/user';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_unprotected')({
	loader: () => {
		if ($isAuthorized.getState()) {
			if (shouldRedirectToOldApplication) {
				window.open(OLD_APP_DOMAIN, '_self');
				return;
			}

			throw redirect({
				to: '/dashboard',
				replace: true,
			});
		}
	},
});
