import { shouldRedirectToOldApplication } from '@config/envs';
import { OLD_APP_ROUTE_NAME } from '@config/routes';
import { $isAuthorized } from '@entities/user';
import { useEffectOnce } from '@hooks/utils';
import { Navigate, createFileRoute } from '@tanstack/react-router';
import { lazy } from 'react';

const HomePage = lazy(() => import('@pages/home'));

export const Route = createFileRoute('/')({
	loader: () => ({
		isAuthorized: $isAuthorized.getState(),
	}),
	component: Page,
});

function Page() {
	const { isAuthorized } = Route.useLoaderData();

	// TODO: remove after migration to new dashboard
	useEffectOnce(() => {
		if (isAuthorized && shouldRedirectToOldApplication) {
			window.open(OLD_APP_ROUTE_NAME.home);
		}
	});

	if (isAuthorized) {
		if (shouldRedirectToOldApplication) {
			return null;
		}

		return <Navigate to="/dashboard" replace />;
	}

	return <HomePage />;
}
