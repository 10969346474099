import { SimpleEligibilityStatus } from '@/shared/types';
import { CreditAccountWithdrawalEligibilityState } from '@/shared/types/credit-account-withdrawal';
import { MIN_USER_DISPOSABLE_INCOME } from '@config/credit-account-withdrawal';
import { OLD_APP_ROUTE_NAME, PURCHASE_FLOW_ROUTE_NAME } from '@config/routes';
import { useAppConfig } from '@hooks/system/useAppConfig';
import { useWithdrawFromCreditAccountMutation } from '@pages/credit-account-withdrawal/form/api';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useState } from 'react';
import { toast } from 'sonner';

type UseWithdrawFromCreditAccountParams = {
	disposableIncomeWithoutCa: number;
};

export const useWithdrawFromCreditAccount = ({
	disposableIncomeWithoutCa,
}: UseWithdrawFromCreditAccountParams) => {
	const { isRejectedCAWRedirectionToCamEnabled } = useAppConfig();
	const navigate = useNavigate();

	const [isWithdrawing, setIsWithdrawing] = useState(false);

	const [withdrawFromCreditAccountMutation] =
		useWithdrawFromCreditAccountMutation();

	const handleWithdrawFromCredit = useCallback(
		async ({
			amount,
			isInstantPayment,
			fromPathname,
			creditAccountId,
		}: {
			amount: number;
			isInstantPayment: boolean;
			fromPathname?: string;
			creditAccountId: number;
		}) => {
			setIsWithdrawing(true);

			try {
				const { data } = await withdrawFromCreditAccountMutation({
					variables: {
						amount,
						isInstantPayment,
						creditAccountId,
					},
				});

				if (!data?.withdraw_from_credit_account) {
					throw new Error('Withdrawal failed');
				}

				const { eligibility_state, hash, simple_eligibility_status } =
					data.withdraw_from_credit_account;

				if (
					eligibility_state === CreditAccountWithdrawalEligibilityState.NEGATIVE
				) {
					if (
						isRejectedCAWRedirectionToCamEnabled &&
						simple_eligibility_status ===
							SimpleEligibilityStatus.DSTI_DSCR_INSUFFICIENT &&
						disposableIncomeWithoutCa > MIN_USER_DISPOSABLE_INCOME
					) {
						window.open(OLD_APP_ROUTE_NAME.creditAccountModification, '_self');
						return;
					}

					await navigate({
						to: '/credit-account-withdrawal/reject',
						search: {
							fromPathname,
							hash,
						},
						replace: true,
					});
					setIsWithdrawing(false);

					return;
				}

				if (
					eligibility_state === CreditAccountWithdrawalEligibilityState.SCORING
				) {
					window.open(
						PURCHASE_FLOW_ROUTE_NAME.creditLineWithdrawal.replace(
							'$hash',
							hash,
						),
						'_self',
					);

					return;
				}

				await navigate({
					to: '/credit-account-withdrawal/success',
					search: {
						fromPathname,
						hash,
					},
					replace: true,
				});
				setIsWithdrawing(false);
			} catch (error) {
				// TODO: ask Edward if we need to handle errors differently
				toast.error('Something went wrong');
				setIsWithdrawing(false);
			}
		},
		[
			navigate,
			withdrawFromCreditAccountMutation,
			isRejectedCAWRedirectionToCamEnabled,
			disposableIncomeWithoutCa,
		],
	);

	return {
		handleWithdrawFromCredit,
		isWithdrawing,
	};
};
