/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import type * as Types from '../../../../shared/types/api.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EparakstsLoginQueryVariables = Types.Exact<{
	code: Types.Scalars['String']['input'];
	return_url: Types.Scalars['String']['input'];
}>;

export type EparakstsLoginQuery = {
	eparaksts_login?: { is_authenticated?: boolean | null } | null;
};

export const EparakstsLoginDocument = gql`
    query EparakstsLogin($code: String!, $return_url: String!) {
  eparaksts_login(code: $code, return_url: $return_url) {
    is_authenticated
  }
}
    `;

/**
 * __useEparakstsLoginQuery__
 *
 * To run a query within a React component, call `useEparakstsLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useEparakstsLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEparakstsLoginQuery({
 *   variables: {
 *      code: // value for 'code'
 *      return_url: // value for 'return_url'
 *   },
 * });
 */
export function useEparakstsLoginQuery(
	baseOptions: Apollo.QueryHookOptions<
		EparakstsLoginQuery,
		EparakstsLoginQueryVariables
	> &
		(
			| { variables: EparakstsLoginQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EparakstsLoginQuery, EparakstsLoginQueryVariables>(
		EparakstsLoginDocument,
		options,
	);
}
export function useEparakstsLoginLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EparakstsLoginQuery,
		EparakstsLoginQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EparakstsLoginQuery, EparakstsLoginQueryVariables>(
		EparakstsLoginDocument,
		options,
	);
}
export function useEparakstsLoginSuspenseQuery(
	baseOptions?: Apollo.SuspenseQueryHookOptions<
		EparakstsLoginQuery,
		EparakstsLoginQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<
		EparakstsLoginQuery,
		EparakstsLoginQueryVariables
	>(EparakstsLoginDocument, options);
}
export type EparakstsLoginQueryHookResult = ReturnType<
	typeof useEparakstsLoginQuery
>;
export type EparakstsLoginLazyQueryHookResult = ReturnType<
	typeof useEparakstsLoginLazyQuery
>;
export type EparakstsLoginSuspenseQueryHookResult = ReturnType<
	typeof useEparakstsLoginSuspenseQuery
>;
export type EparakstsLoginQueryResult = Apollo.QueryResult<
	EparakstsLoginQuery,
	EparakstsLoginQueryVariables
>;
