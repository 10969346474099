import { APP_CONFIG } from '@config/app';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useAppConfig = () => {
	const { i18n } = useTranslation();

	return useMemo(
		() => ({
			authMethods: APP_CONFIG.authMethods,
			starProduct: APP_CONFIG.starProduct,
			supportUrl: APP_CONFIG.supportUrlByLanguage[i18n.language],
			legalInfo: APP_CONFIG.legalInfo,
			termsUrl: APP_CONFIG.termsUrlByLanguage[i18n.language],
			phoneCode: APP_CONFIG.phoneCode,
			isCawInstantPaymentDefaultEnabled:
				APP_CONFIG.isCawInstantPaymentDefaultEnabled,
			isRejectedCAWRedirectionToCamEnabled:
				APP_CONFIG.isRejectedCAWRedirectionToCamEnabled,
		}),
		[i18n.language],
	);
};
