import { apolloClient } from '@/shared/lib/apolloClient';
import type { AppLanguage } from '@/shared/types';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { ActiveLanguagesDocument, type ActiveLanguagesQuery } from './api';
import { serializeActiveLanguages } from './utils';

const initLocalizationFx = createEffect(async () => {
	const { data } = await apolloClient.query<ActiveLanguagesQuery>({
		query: ActiveLanguagesDocument,
	});

	return serializeActiveLanguages(data);
});

const initLocalizationEv = createEvent('init localization');

const $activeLanguages = createStore<Array<AppLanguage>>([]).on(
	initLocalizationFx.doneData,
	(_, activeLanguages) => activeLanguages,
);
const $isLocalizationInitialized = createStore(false);

sample({
	clock: initLocalizationEv,
	target: initLocalizationFx,
});

sample({
	clock: initLocalizationFx.done,
	fn: () => true,
	target: $isLocalizationInitialized,
});

export { initLocalizationEv, $activeLanguages, $isLocalizationInitialized };
