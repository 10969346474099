import type { CreditAccountWithdrawalFormType } from '@features/credit-account/withdrawal-form';
import { useAppConfig } from '@hooks/system/useAppConfig';
import { getRouteApi } from '@tanstack/react-router';
import { lazy, useCallback, useState } from 'react';
import {
	useCalculateNewMonthlyPayment,
	useWithdrawFromCreditAccount,
} from '../hooks';

const CreditAccountWithdrawalForm = lazy(() =>
	import('@features/credit-account/withdrawal-form').then((m) => ({
		default: m.CreditAccountWithdrawalForm,
	})),
);
const CreditAccountWithdrawalFormConfirmView = lazy(() =>
	import('./CreditAccountWithdrawalFormConfirmView').then((m) => ({
		default: m.CreditAccountWithdrawalFormConfirmView,
	})),
);

const CreditAccountWithdrawalFormPendingView = lazy(() =>
	import('./CreditAccountWithdrawalFormPendingView').then((m) => ({
		default: m.CreditAccountWithdrawalFormPendingView,
	})),
);

const routeApi = getRouteApi('/_protected/credit-account-withdrawal/form');

export const CreditAccountWithdrawalFormPage = () => {
	const {
		maxWithdrawalAmount,
		creditAccountId,
		minInstantWithdrawalAmount,
		minWithdrawalAmount,
		annualPctRate,
		maxPeriodMonths,
		unpaidPrincipal,
		instantPaymentFee,
		disposableIncomeWithoutCa,
	} = routeApi.useLoaderData();
	const { isCawInstantPaymentDefaultEnabled } = useAppConfig();

	const [withdrawData, setWithdrawData] =
		useState<Nullable<CreditAccountWithdrawalFormType>>(null);
	const [isConfirming, setIsConfirming] = useState(false);

	const { fromPathname } = routeApi.useSearch();

	const { handleWithdrawFromCredit, isWithdrawing } =
		useWithdrawFromCreditAccount({
			disposableIncomeWithoutCa,
		});

	const handleFormSubmit = useCallback(
		({ amount, isInstantPayment }: CreditAccountWithdrawalFormType) => {
			setWithdrawData({ amount, isInstantPayment });
			setIsConfirming(true);
		},
		[],
	);

	const handleWithdrawConfirm = useCallback(() => {
		if (!withdrawData) {
			throw new Error('Withdraw data is missing');
		}

		handleWithdrawFromCredit({
			amount: withdrawData.amount,
			isInstantPayment: withdrawData.isInstantPayment,
			fromPathname,
			creditAccountId,
		});
	}, [creditAccountId, fromPathname, handleWithdrawFromCredit, withdrawData]);

	const calculateNewMonthlyPayment = useCalculateNewMonthlyPayment({
		annualPctRate,
		maxPeriodMonths,
		unpaidPrincipal,
	});

	if (isWithdrawing) {
		return <CreditAccountWithdrawalFormPendingView />;
	}

	if (isConfirming && withdrawData) {
		return (
			<CreditAccountWithdrawalFormConfirmView
				amount={withdrawData.amount}
				onSubmit={handleWithdrawConfirm}
				onClose={() => setIsConfirming(false)}
			/>
		);
	}

	return (
		<CreditAccountWithdrawalForm
			calculateNewMonthlyPaymentFn={calculateNewMonthlyPayment}
			instantPaymentFee={instantPaymentFee}
			maxWithdrawalAmount={maxWithdrawalAmount}
			minInstantWithdrawalAmount={minInstantWithdrawalAmount}
			minWithdrawalAmount={minWithdrawalAmount}
			onSubmit={handleFormSubmit}
			isCawInstantPaymentDefaultEnabled={isCawInstantPaymentDefaultEnabled}
			defaultValues={withdrawData}
		/>
	);
};
