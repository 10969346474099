import { isProd } from '@/shared/config/envs';
import type { apolloClient } from '@/shared/lib/apolloClient';
import { LoadingScreen, routeChangedEv } from '@entities/app';
import { NotFoundPage } from '@pages/404';
import { ErrorPage } from '@pages/error';
import {
	Outlet,
	ScrollRestoration,
	createRootRouteWithContext,
} from '@tanstack/react-router';
import { lazy } from 'react';

const TanStackRouterDevtools = lazy(() =>
	import('@tanstack/router-devtools').then((res) => ({
		default: res.TanStackRouterDevtools,
	})),
);

type MyRouterContext = {
	apolloClient: typeof apolloClient;
};

export const Route = createRootRouteWithContext<MyRouterContext>()({
	loader: () => {
		routeChangedEv();
	},
	component: () => (
		<>
			<ScrollRestoration />
			<Outlet />
			{isProd ? null : <TanStackRouterDevtools />}
		</>
	),
	errorComponent: ErrorPage,
	notFoundComponent: NotFoundPage,
	pendingComponent: LoadingScreen,
});
