import { GRAPHQL_SERVER_URL, isProd } from '@/shared/config/envs';
import {
	ApolloClient,
	HttpLink,
	InMemoryCache,
	defaultDataIdFromObject,
} from '@apollo/client';
import fetch from 'cross-fetch';

const link = new HttpLink({
	uri: GRAPHQL_SERVER_URL,
	fetch,
	credentials: 'include',
});

const cache = new InMemoryCache({
	dataIdFromObject(responseObject) {
		switch (responseObject.__typename) {
			case 'UserProfile':
				return `UserProfile:${responseObject.user_id}`;
			default:
				return defaultDataIdFromObject(responseObject);
		}
	},
});

export const apolloClient = new ApolloClient({
	link,
	cache,
	connectToDevTools: !isProd,
});
