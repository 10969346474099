import { RouteName } from '@config/routes';
import { createFileRoute } from '@tanstack/react-router';
import * as z from 'zod';

const UserNewsletterInfoSearch = z.object({
	fromPathname: z.string().catch(RouteName.DASHBOARD),
});

export const Route = createFileRoute('/_protected/subscribe-newsletter')({
	validateSearch: UserNewsletterInfoSearch,
});
