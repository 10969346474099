import { APP_CONFIG } from '@config/app';
import { LANGUAGE_SHORTNAME_BY_ABBREVIATIONS_MAP } from '@entities/languages';
import i18n from '@lib/i18Next';
import {
	UserAdditionalInfoDocument,
	type UserAdditionalInfoQuery,
} from '@pages/auth-additional-info/api';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_protected/auth-additional-info')({
	loader: async ({ location, context: { apolloClient } }) => {
		const { data } = await apolloClient.query<UserAdditionalInfoQuery>({
			query: UserAdditionalInfoDocument,
			fetchPolicy: 'network-only',
		});

		if (!data.me) {
			throw redirect({
				to: '/auth',
				search: {
					redirectUrl: location.href,
					authMethod: APP_CONFIG.authMethods[0],
				},
				replace: true,
			});
		}

		const {
			phone,
			email,
			id,
			created_at: createdAt,
			language_abbr: languageAbbr,
			newsletter_agreement: isAcceptedNewsLetterAgreement,
			profile,
		} = data.me;

		const language = LANGUAGE_SHORTNAME_BY_ABBREVIATIONS_MAP[languageAbbr];

		if (language) {
			i18n.changeLanguage(language);
		}

		if ([phone, email].every(Boolean)) {
			throw redirect({
				to: '/',
				replace: true,
			});
		}

		return {
			phone: phone ?? '',
			email: email ?? '',
			userId: id,
			createdAt,
			isAcceptedNewsLetterAgreement,
			firstName: profile?.first_name ?? '',
		};
	},
});
