import { APP_CONFIG } from '@config/app';
import { $isAuthorized } from '@entities/user';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_protected')({
	loader: ({ location }) => {
		if (!$isAuthorized.getState()) {
			throw redirect({
				to: '/auth',
				search: {
					redirectUrl: location.pathname,
					authMethod: APP_CONFIG.authMethods[0],
				},
				replace: true,
			});
		}
	},
});
