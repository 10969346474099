import { apolloClient } from '@/shared/lib/apolloClient';
import { LANGUAGE_SHORTNAME_BY_ABBREVIATIONS_MAP } from '@entities/languages';
import {
	AuthCheckDocument,
	type AuthCheckQuery,
	type UserAuthInfoFragment,
} from '@entities/user';
import i18n from '@lib/i18Next';
import { Sift } from '@lib/sift';
import {
	attach,
	createEffect,
	createEvent,
	createStore,
	sample,
} from 'effector';

const checkUserOnFirstLoadEv = createEvent('check user on first load');
const logoutEv = createEvent('logout');
const setUserEv = createEvent<Nullable<UserAuthInfoFragment>>('set user');

const getUserFx = createEffect(async () => {
	const { data } = await apolloClient.query<AuthCheckQuery>({
		query: AuthCheckDocument,
		fetchPolicy: 'network-only',
	});

	if (
		data?.me?.language_abbr &&
		LANGUAGE_SHORTNAME_BY_ABBREVIATIONS_MAP[data.me.language_abbr]
	) {
		i18n.changeLanguage(
			LANGUAGE_SHORTNAME_BY_ABBREVIATIONS_MAP[data.me.language_abbr],
		);
	}

	return data?.me;
});

const checkUserOnFirstLoadFx = createEffect(async () => {
	await getUserFx();
});

const $user = createStore<Nullable<UserAuthInfoFragment>>(null).reset(logoutEv);
const $isAuthorized = $user.map((user) => !!user);
const $userMarketingConsentInfo = $user.map((user) =>
	user
		? {
				phone: user.phone,
				email: user.email,
				id: user.id,
				newsletter_agreement: user.newsletter_agreement,
			}
		: null,
);

const $isAuthChecked = createStore(false).on(
	checkUserOnFirstLoadFx.done,
	() => true,
);

const identifyUserFx = attach({
	source: { user: $user },
	effect({ user }) {
		if (!user) return;

		Sift.identifyUser(user.id);
	},
});

sample({
	clock: checkUserOnFirstLoadEv,
	target: checkUserOnFirstLoadFx,
});

sample({
	clock: [setUserEv, getUserFx.doneData],
	filter: Boolean,
	target: $user,
});

sample({
	clock: $user,
	filter: Boolean,
	target: identifyUserFx,
});

export {
	$user,
	$userMarketingConsentInfo,
	logoutEv,
	$isAuthorized,
	$isAuthChecked,
	checkUserOnFirstLoadEv,
	getUserFx,
	setUserEv,
};
