import { withProviders } from '@/app/providers';
import { AppRouter } from '@/app/router/AppRouter';
import { Toaster } from '@components/ui/sonner';
import '@config/envs';
import { SIFT_BEACON_KEY } from '@config/envs';
import { $isAppInitialized, initAppEv } from '@entities/app';
import { useEffectOnce } from '@hooks/utils';
import '@lib/i18Next';
import { Sift } from '@lib/sift';
import { useUnit } from 'effector-react';
import './sentry';
import './styles/index.css';

const App = () => {
	const isAppInitialized = useUnit($isAppInitialized);

	useEffectOnce(() => {
		initAppEv();
		if (SIFT_BEACON_KEY) {
			Sift.initialize(SIFT_BEACON_KEY);
		}
	});

	if (!isAppInitialized) {
		return null;
	}

	return (
		<>
			<AppRouter />
			<Toaster />
		</>
	);
};

export default withProviders(App);
