/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import type * as Types from '../../../../../shared/types/api.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PayseraMethodFragment = {
	key: string;
	title: string;
	logo_url: string;
};

export type PayseraPaymentMethodsQueryVariables = Types.Exact<{
	language_abbr: Types.Scalars['String']['input'];
}>;

export type PayseraPaymentMethodsQuery = {
	paysera_payment_methods?: Array<{
		key: string;
		title: string;
		logo_url: string;
	} | null> | null;
};

export type PayseraLoginChallengeMutationVariables = Types.Exact<{
	pin: Types.Scalars['String']['input'];
	payment_method_key: Types.Scalars['String']['input'];
	magic_login_url: Types.Scalars['String']['input'];
	accept_url: Types.Scalars['String']['input'];
	cancel_url: Types.Scalars['String']['input'];
	invite?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type PayseraLoginChallengeMutation = {
	paysera_login_challenge?: {
		session_id?: string | null;
		redirect_url?: string | null;
	} | null;
};

export const PayseraMethodFragmentDoc = gql`
    fragment PayseraMethod on PayseraPaymentMethod {
  key
  title
  logo_url
}
    `;
export const PayseraPaymentMethodsDocument = gql`
    query PayseraPaymentMethods($language_abbr: String!) {
  paysera_payment_methods(language_abbr: $language_abbr) {
    ...PayseraMethod
  }
}
    ${PayseraMethodFragmentDoc}`;

/**
 * __usePayseraPaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePayseraPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayseraPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayseraPaymentMethodsQuery({
 *   variables: {
 *      language_abbr: // value for 'language_abbr'
 *   },
 * });
 */
export function usePayseraPaymentMethodsQuery(
	baseOptions: Apollo.QueryHookOptions<
		PayseraPaymentMethodsQuery,
		PayseraPaymentMethodsQueryVariables
	> &
		(
			| { variables: PayseraPaymentMethodsQueryVariables; skip?: boolean }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		PayseraPaymentMethodsQuery,
		PayseraPaymentMethodsQueryVariables
	>(PayseraPaymentMethodsDocument, options);
}
export function usePayseraPaymentMethodsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		PayseraPaymentMethodsQuery,
		PayseraPaymentMethodsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		PayseraPaymentMethodsQuery,
		PayseraPaymentMethodsQueryVariables
	>(PayseraPaymentMethodsDocument, options);
}
export function usePayseraPaymentMethodsSuspenseQuery(
	baseOptions?: Apollo.SuspenseQueryHookOptions<
		PayseraPaymentMethodsQuery,
		PayseraPaymentMethodsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<
		PayseraPaymentMethodsQuery,
		PayseraPaymentMethodsQueryVariables
	>(PayseraPaymentMethodsDocument, options);
}
export type PayseraPaymentMethodsQueryHookResult = ReturnType<
	typeof usePayseraPaymentMethodsQuery
>;
export type PayseraPaymentMethodsLazyQueryHookResult = ReturnType<
	typeof usePayseraPaymentMethodsLazyQuery
>;
export type PayseraPaymentMethodsSuspenseQueryHookResult = ReturnType<
	typeof usePayseraPaymentMethodsSuspenseQuery
>;
export type PayseraPaymentMethodsQueryResult = Apollo.QueryResult<
	PayseraPaymentMethodsQuery,
	PayseraPaymentMethodsQueryVariables
>;
export const PayseraLoginChallengeDocument = gql`
    mutation PayseraLoginChallenge($pin: String!, $payment_method_key: String!, $magic_login_url: String!, $accept_url: String!, $cancel_url: String!, $invite: String) {
  paysera_login_challenge(
    pin: $pin
    payment_method_key: $payment_method_key
    magic_login_url: $magic_login_url
    accept_url: $accept_url
    cancel_url: $cancel_url
    invite: $invite
  ) {
    session_id
    redirect_url
  }
}
    `;
export type PayseraLoginChallengeMutationFn = Apollo.MutationFunction<
	PayseraLoginChallengeMutation,
	PayseraLoginChallengeMutationVariables
>;

/**
 * __usePayseraLoginChallengeMutation__
 *
 * To run a mutation, you first call `usePayseraLoginChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayseraLoginChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payseraLoginChallengeMutation, { data, loading, error }] = usePayseraLoginChallengeMutation({
 *   variables: {
 *      pin: // value for 'pin'
 *      payment_method_key: // value for 'payment_method_key'
 *      magic_login_url: // value for 'magic_login_url'
 *      accept_url: // value for 'accept_url'
 *      cancel_url: // value for 'cancel_url'
 *      invite: // value for 'invite'
 *   },
 * });
 */
export function usePayseraLoginChallengeMutation(
	baseOptions?: Apollo.MutationHookOptions<
		PayseraLoginChallengeMutation,
		PayseraLoginChallengeMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		PayseraLoginChallengeMutation,
		PayseraLoginChallengeMutationVariables
	>(PayseraLoginChallengeDocument, options);
}
export type PayseraLoginChallengeMutationHookResult = ReturnType<
	typeof usePayseraLoginChallengeMutation
>;
export type PayseraLoginChallengeMutationResult =
	Apollo.MutationResult<PayseraLoginChallengeMutation>;
export type PayseraLoginChallengeMutationOptions = Apollo.BaseMutationOptions<
	PayseraLoginChallengeMutation,
	PayseraLoginChallengeMutationVariables
>;
