/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import type * as Types from '../../../../shared/types/api.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserCreditAccountWithdrawalSuccessInfoQueryVariables = Types.Exact<{
	hash: Types.Scalars['String']['input'];
}>;

export type UserCreditAccountWithdrawalSuccessInfoQuery = {
	current_credit_account_withdrawal?: {
		id: number;
		type: Types.CreditAccountWithdrawalType;
		eligibility_state: number;
		simple_eligibility_status: string;
	} | null;
};

export const UserCreditAccountWithdrawalSuccessInfoDocument = gql`
    query UserCreditAccountWithdrawalSuccessInfo($hash: String!) {
  current_credit_account_withdrawal(hash: $hash) {
    id
    type
    eligibility_state
    simple_eligibility_status
  }
}
    `;

/**
 * __useUserCreditAccountWithdrawalSuccessInfoQuery__
 *
 * To run a query within a React component, call `useUserCreditAccountWithdrawalSuccessInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreditAccountWithdrawalSuccessInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreditAccountWithdrawalSuccessInfoQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useUserCreditAccountWithdrawalSuccessInfoQuery(
	baseOptions: Apollo.QueryHookOptions<
		UserCreditAccountWithdrawalSuccessInfoQuery,
		UserCreditAccountWithdrawalSuccessInfoQueryVariables
	> &
		(
			| {
					variables: UserCreditAccountWithdrawalSuccessInfoQueryVariables;
					skip?: boolean;
			  }
			| { skip: boolean }
		),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		UserCreditAccountWithdrawalSuccessInfoQuery,
		UserCreditAccountWithdrawalSuccessInfoQueryVariables
	>(UserCreditAccountWithdrawalSuccessInfoDocument, options);
}
export function useUserCreditAccountWithdrawalSuccessInfoLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		UserCreditAccountWithdrawalSuccessInfoQuery,
		UserCreditAccountWithdrawalSuccessInfoQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		UserCreditAccountWithdrawalSuccessInfoQuery,
		UserCreditAccountWithdrawalSuccessInfoQueryVariables
	>(UserCreditAccountWithdrawalSuccessInfoDocument, options);
}
export function useUserCreditAccountWithdrawalSuccessInfoSuspenseQuery(
	baseOptions?: Apollo.SuspenseQueryHookOptions<
		UserCreditAccountWithdrawalSuccessInfoQuery,
		UserCreditAccountWithdrawalSuccessInfoQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<
		UserCreditAccountWithdrawalSuccessInfoQuery,
		UserCreditAccountWithdrawalSuccessInfoQueryVariables
	>(UserCreditAccountWithdrawalSuccessInfoDocument, options);
}
export type UserCreditAccountWithdrawalSuccessInfoQueryHookResult = ReturnType<
	typeof useUserCreditAccountWithdrawalSuccessInfoQuery
>;
export type UserCreditAccountWithdrawalSuccessInfoLazyQueryHookResult =
	ReturnType<typeof useUserCreditAccountWithdrawalSuccessInfoLazyQuery>;
export type UserCreditAccountWithdrawalSuccessInfoSuspenseQueryHookResult =
	ReturnType<typeof useUserCreditAccountWithdrawalSuccessInfoSuspenseQuery>;
export type UserCreditAccountWithdrawalSuccessInfoQueryResult =
	Apollo.QueryResult<
		UserCreditAccountWithdrawalSuccessInfoQuery,
		UserCreditAccountWithdrawalSuccessInfoQueryVariables
	>;
