import { APP_COUNTRY, SENTRY_DSN } from '@config/envs';
import * as Sentry from '@sentry/react';

if (SENTRY_DSN) {
	Sentry.init({
		dsn: SENTRY_DSN,
		environment: APP_COUNTRY,
		integrations: [Sentry.browserTracingIntegration()],
		// Performance Monitoring
		tracesSampleRate: 0.1, //  Capture 100% of the transactions
	});
}
