/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import type * as Types from '../../../../shared/types/api.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WithdrawFromCreditAccountMutationVariables = Types.Exact<{
	creditAccountId: Types.Scalars['Int']['input'];
	amount: Types.Scalars['Float']['input'];
	isInstantPayment: Types.Scalars['Boolean']['input'];
}>;

export type WithdrawFromCreditAccountMutation = {
	withdraw_from_credit_account?: {
		eligibility_state: number;
		hash: string;
		simple_eligibility_status: string;
	} | null;
};

export const WithdrawFromCreditAccountDocument = gql`
    mutation WithdrawFromCreditAccount($creditAccountId: Int!, $amount: Float!, $isInstantPayment: Boolean!) {
  withdraw_from_credit_account(
    credit_account_id: $creditAccountId
    amount: $amount
    is_instant_payment: $isInstantPayment
  ) {
    eligibility_state
    hash
    simple_eligibility_status
  }
}
    `;
export type WithdrawFromCreditAccountMutationFn = Apollo.MutationFunction<
	WithdrawFromCreditAccountMutation,
	WithdrawFromCreditAccountMutationVariables
>;

/**
 * __useWithdrawFromCreditAccountMutation__
 *
 * To run a mutation, you first call `useWithdrawFromCreditAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawFromCreditAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawFromCreditAccountMutation, { data, loading, error }] = useWithdrawFromCreditAccountMutation({
 *   variables: {
 *      creditAccountId: // value for 'creditAccountId'
 *      amount: // value for 'amount'
 *      isInstantPayment: // value for 'isInstantPayment'
 *   },
 * });
 */
export function useWithdrawFromCreditAccountMutation(
	baseOptions?: Apollo.MutationHookOptions<
		WithdrawFromCreditAccountMutation,
		WithdrawFromCreditAccountMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		WithdrawFromCreditAccountMutation,
		WithdrawFromCreditAccountMutationVariables
	>(WithdrawFromCreditAccountDocument, options);
}
export type WithdrawFromCreditAccountMutationHookResult = ReturnType<
	typeof useWithdrawFromCreditAccountMutation
>;
export type WithdrawFromCreditAccountMutationResult =
	Apollo.MutationResult<WithdrawFromCreditAccountMutation>;
export type WithdrawFromCreditAccountMutationOptions =
	Apollo.BaseMutationOptions<
		WithdrawFromCreditAccountMutation,
		WithdrawFromCreditAccountMutationVariables
	>;
