import { GLOBAL_LOCAL_STORAGE_KEYS } from '@config/common';
import { APP_COUNTRY } from '@config/envs';
import { v4 as uuid } from 'uuid';

export const initialize = (siftBeaconKey: string) => {
	if (window._sift) {
		return;
	}
	let sessionId = localStorage.getItem(
		GLOBAL_LOCAL_STORAGE_KEYS.browsingSessionId,
	);

	if (!sessionId) {
		sessionId = uuid();
		localStorage.setItem(
			GLOBAL_LOCAL_STORAGE_KEYS.browsingSessionId,
			sessionId,
		);
	}

	const siftScript = document.createElement('script');
	siftScript.type = 'text/javascript';
	siftScript.src = 'https://cdn.sift.com/s.js';
	document.body.insertAdjacentElement('afterbegin', siftScript);

	window._sift = window._sift || [];

	window._sift.push(['_setSessionId', sessionId]);
	window._sift.push(['_setAccount', siftBeaconKey]);
	window._sift.push(['_trackPageview']);
};

const identifyUser = (userId: number) => {
	if (!window._sift) {
		return;
	}

	const userIdentifier = userId ? `${APP_COUNTRY}-${userId}` : null;

	window._sift.push(['_setUserId', userIdentifier]);
};

const trackPageView = () => {
	if (!window._sift) {
		return;
	}

	window._sift.push(['_trackPageview']);
};

export const Sift = {
	initialize,
	identifyUser,
	trackPageView,
};
