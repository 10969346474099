import * as z from 'zod';
import { PAYSERA_BANKLINK_AUTH_FORM_ERRORS } from './config';

export const PayseraBanklinkLoginFormSchema = z.object({
	pin: z.string().min(1, {
		message: PAYSERA_BANKLINK_AUTH_FORM_ERRORS.required,
	}),
	payment_method_key: z.string().min(1, {
		message: PAYSERA_BANKLINK_AUTH_FORM_ERRORS.required,
	}),
});

export type PayseraBanklinkLoginFormType = z.infer<
	typeof PayseraBanklinkLoginFormSchema
>;
