type GetFullNameParams<T> = {
	firstName?: Nullable<string>;
	lastName?: Nullable<string>;
	defaultValue: T;
};

export const getFullName = <T>({
	firstName,
	lastName,
	defaultValue,
}: GetFullNameParams<T>) => `${firstName} ${lastName}`.trim() || defaultValue;
