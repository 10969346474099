import { APP_COUNTRY, RUDDERSTACK_API_KEY } from '@config/envs';
import { $user } from '@entities/user';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import {
	attach,
	createEffect,
	createEvent,
	createStore,
	sample,
} from 'effector';

type TrackData = {
	event: string;
	properties?: {
		[index: string]:
			| string
			| number
			| boolean
			| (string | number | boolean)[]
			| undefined;
	};
};

const rudderAnalytics = new RudderAnalytics();

const initFx = createEffect((rudderstackId: string) => {
	rudderAnalytics.load(
		rudderstackId,
		'https://estomikkxgm.dataplane.rudderstack.com',
	);
});

const trackFx = createEffect(({ event, properties }: TrackData) => {
	rudderAnalytics.track(event, properties);
});

const initEv = createEvent('init rudderstack');
const trackEv = createEvent<TrackData>('track event in rudderstack');

const identifyUserFx = attach({
	source: $user,
	effect(user) {
		if (!user) {
			return;
		}

		const { id, email, profile } = user;

		const anonymousId = rudderAnalytics.getAnonymousId();
		const userIdAsString = `${APP_COUNTRY}-${id}`;

		rudderAnalytics.alias(userIdAsString, anonymousId);
		rudderAnalytics.identify(userIdAsString, {
			firstName: profile?.first_name || 'No First Name',
			lastName: profile?.last_name || 'No Last Name',
			email: email ?? 'No Email',
			country: APP_COUNTRY,
		});
	},
});

const $isInitialized = createStore(false).on(initFx.done, () => true);

sample({
	clock: initEv,
	source: $isInitialized,
	filter: (isInitialized) => !isInitialized && !!RUDDERSTACK_API_KEY,
	fn: () => RUDDERSTACK_API_KEY ?? '',
	target: initFx,
});

sample({
	clock: trackEv,
	source: $isInitialized,
	filter: (isInitialized) => !!isInitialized,
	fn: (_, eventData) => eventData,
	target: trackFx,
});

sample({
	source: { user: $user, isInitialized: $isInitialized },
	filter: ({ user, isInitialized }) => !!user && isInitialized,
	fn: ({ user }) => user,
	target: identifyUserFx,
});

export const rudderstackModel = {
	initEv,
	trackEv,
};
